import React, { useCallback, useMemo, useState } from "react";
import PT from "prop-types";
import block from "bem-cn";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SVGInline from "react-svg-inline";

import { actions as usersActions } from "features/users/redux";

import { actions } from "features/tree";

import arrowSvg from "../img/down-arrow.svg";
import banSvg from "../img/ban.svg";

import "./TreeRow.scss";

const b = block("tree-row");

const TreeRow = ({ row, nesting }) => {
  const dispatch = useDispatch();
  const { username, role, userId, players, balance, isBanned, subusersCount } = row;
  const [isOpened, setOpened] = useState(row.childrens?.length ? false : null);

  const locale = useSelector((state) => state.locale.locale, shallowEqual);

  const handleLoadUsersList = useCallback(() => {
    dispatch(actions.getUsersByUserId({ id: userId, nesting }));
  }, [nesting, dispatch, userId]);

  const onClick = useCallback(() => {
    if (subusersCount) {
      if (isOpened === null) {
        setOpened(true);
        handleLoadUsersList();
      } else {
        setOpened((prevState) => !prevState);
      }
    }
  }, [isOpened, subusersCount, handleLoadUsersList]);

  const childrens = useMemo(
    () =>
      row.childrens?.map((t) => (
        <TreeRow key={t.userId} nesting={[...nesting, t.userId]} row={t} />
      )) || [],
    [row, nesting]
  );

  const onBanClick = useCallback(() => {
    // dispatch(
    //   usersActions.changeBanState(
    //     userId,
    //     !isBanned,
    //     nesting.length > 1
    //       ? handleLoadUsersList
    //       : () => {
    //           dispatch(actions.getUsersByUserId());
    //         }
    //   )
    // );
    dispatch(actions.banUser({ id: userId, nesting, value: !isBanned }));
  }, [dispatch, userId, isBanned, nesting]);

  return (
    <div className={b()}>
      <div className={b("parent")}>
        <div className={b("td")} onClick={onClick}>
          {subusersCount ? (
            <SVGInline
              svg={arrowSvg}
              className={b("arrow", { opened: isOpened })}
            />
          ) : (
            ""
          )}
          <span className={b("username", { banned: isBanned })}>
            {username}
          </span>
          {role !== 0 ? `| ${locale.players}: ${players} | ${locale.subusers}: ${subusersCount} | ${locale.balance}: ${balance}` : `| ${locale.balance}: ${balance}`}
        </div>
        <SVGInline
          svg={banSvg}
          className={b("ban", { banned: isBanned })}
          onClick={onBanClick}
        />
      </div>
      {isOpened && <div className={b("childrens")}>{childrens}</div>}
    </div>
  );
};

TreeRow.propTypes = {};

export default TreeRow;
