import React, { useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions as notifyActions } from 'features/notify';

import './Copy.scss';

const Copy = ({ children, value }) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const onCopy = useCallback(() => {
    dispatch(notifyActions.addNotify(locale.copiedSuccessfully, 'success'));
  }, [dispatch, locale]);

  return (
    <CopyToClipboard text={value} onCopy={onCopy}>
      {children}
    </CopyToClipboard>
  );
};

export default Copy;
