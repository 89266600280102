import React, { useMemo, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux'

import Modal from 'components/Modal';
import Button from 'components/Button';
import SVG from 'components/SVG';

import { actions } from 'features/Auth';

import './LogOut.scss';

const b = block('log-out');

const LogOut = ({ children }) => {
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const handleFormSubmit = e => {
    e.preventDefault();
    dispatch(actions.logOut());
    setIsOpen(false);
  }

  return <section className={b()}>
    {isOpen && <Modal onClose={() => setIsOpen(false)}>
      <form className={b('modal')} onSubmit={handleFormSubmit}>
        <h5 className={b('modal-title')}>{locale.logout}</h5>
        <h5 className={b('modal-text')}>{locale.areUSure}</h5>
        <div className={b('modal-buttons')}>
          <div className={b('modal-button')}>
            <Button text={locale.comeBack} onClick={() => setIsOpen(false)} />
          </div>
          <div className={b('modal-button')}>
            <Button text={locale.yes} type='submit' color="transparent" />
          </div>
        </div>
      </form>
    </Modal>}
    <div className={b('children')} onClick={() => setIsOpen(!isOpen)}>
      {children}
    </div>
  </section>
}

export default LogOut;
