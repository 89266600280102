import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';
import { Link } from 'react-router-dom';

import SVG from 'components/SVG';

import { actions } from 'features/app';

import { sideMenuItems } from './data';
import './SideMenu.scss';

const b = block('side-menu');

const SideMenu = () => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const dispatch = useDispatch();

  const handleItemClick = () => {
    dispatch(actions.changeSideMenuOpened(false));
  }

  const blocks = sideMenuItems.map((itemsData, i) => {
    const items = itemsData.map(item => {

      return (
        <Link className={b('item')} to={item.link} key={item.text} onClick={handleItemClick}>
          <SVG svgProps={{ svg: item.icon }} className={b('item-icon')} />
          {locale[item.text] ?? item.text}
          {item.additional && (
            <Link className={b('item-additional')} to={item.additional?.link ?? '#'}>
              <SVG svgProps={{ svg: item.additional?.icon }} className={b('item-icon')} />
            </Link>
          )}
        </Link>
      )
    });

    return (
      <div className={b('block')} key={`block_${i}`}>
        {items}
      </div>
    )
  });

  return (
    <div className={b()}>
      {blocks}
    </div>
  );
};

export default SideMenu;
