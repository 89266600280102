const actionTypes = {
  CHANGE_SIDE_MENU_OPENED: 'app/CHANGE_SIDE_MENU_OPENED',
  CHANGE_DOWN_MENU_OPENED: 'app/CHANGE_DOWN_MENU_OPENED',
  CHANGE_THEME: 'app/CHANGE_THEME',
};

function changeSideMenuOpened(isOpened = false) {
  return async dispatch => {
    dispatch({ type: actionTypes.CHANGE_SIDE_MENU_OPENED, payload: isOpened });
  };
}

export { actionTypes, changeSideMenuOpened };
