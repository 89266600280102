import React from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatNumber } from 'shared/utils/formatNumber';

import SVG from 'components/SVG';
import arrowSvg from './img/arrow.svg';

import './UserItem.scss';

const b = block('user-item');

const UserItem = ({ item }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const user = useSelector(state => state.auth);

  const role = locale.roles[user.role - 1];

  return (
    <Link className={b()} to={`/user-edit/${item.id}`}>
      <div className={b('elem')}>
        <div className={b('elem-label')}>{locale.userId}</div>
        <div className={b('elem-value')}>{item.id}</div>
      </div>
      <div className={b('elem', { type: 'large' })}>
        <div className={b('elem-label')}>{locale.userName}</div>
        <div className={b('elem-value')}>{item.nickname}</div>
      </div>
      <div className={b('elem')}>
        <div className={b('elem-label')}>{locale.role}</div>
        <div className={b('elem-value')}>{role}</div>
      </div>
      <div className={b('elem')}>
        <div className={b('elem-label')}>{locale.balance}</div>
        <div className={b('elem-value')}>{formatNumber(item.balance)}</div>
      </div>
      <SVG svgProps={{ svg: arrowSvg }} className={b('arrow')} />
    </Link>
  );
};

export default UserItem;