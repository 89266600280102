import React, { useMemo, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux'

import Modal from 'components/Modal';
import Button from 'components/Button';
import SVG from 'components/SVG';

import { languagesWithIcons } from 'services/locale';
import { actions } from 'features/locale';

import langSVG from 'shared/img/lang.svg';

import './SetLanguage.scss';

const b = block('set-language');

const SetLanguage = ({ children }) => {
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang);
  const dispatch = useDispatch();

  const [activeLang, setActiveLang] = useState(lang);
  const [isOpen, setIsOpen] = useState(false);

  const langItems = useMemo(() =>
    Object.entries(languagesWithIcons)
      .map(temp =>
        <div
          key={temp[0]}
          className={b('item', { active: activeLang === temp[0] })}
          onClick={() => setActiveLang(temp[0])}
        >
          <img src={temp[1].icon} alt="" className={b('item-image')} />
          {temp[1].text}
        </div>
  ), [activeLang]);

  const handleFormSubmit = e => {
    e.preventDefault();
    dispatch(actions.changeLang(activeLang));
    setIsOpen(false);
  }

  return <section className={b()}>
    {isOpen && <Modal onClose={() => setIsOpen(false)}>
      <form className={b('modal')} onSubmit={handleFormSubmit}>
        <h5 className={b('modal-title')}>{locale.changeLang}</h5>
        <div className={b('modal-list')}>{langItems}</div>
        <div className={b('modal-button')}>
          <Button text={locale.changeLang} type='submit' />
        </div>
      </form>
    </Modal>}
    <div className={b('children')} onClick={() => setIsOpen(!isOpen)}>
      {children ?? <div className={b('base')}>
        <SVG className={b('base-icon')} svgProps={{ svg: langSVG }} />
        {locale.changeLang}
      </div>}
    </div>
  </section>
}

export default SetLanguage;
