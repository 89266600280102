import React from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual } from 'react-redux';

import { formatNumber } from 'shared/utils/formatNumber';

import './TotalUser.scss';

const b = block('total-user');

const TotalUser = ({ item, onClick }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const user = useSelector(state => state.auth);

  const role = locale.roles[user.role - 1];

  return (
    <div className={b()} onClick={onClick}>
      <div className={b('item')}>
        <div className={b('item-label')}>{locale.userId}</div>
        <div className={b('item-value')}>{item.userId}</div>
      </div>
      <div className={b('item')}>
        <div className={b('item-label')}>{locale.userName}</div>
        <div className={b('item-value')}>{item.username}</div>
      </div>
      <div className={b('item')}>
        <div className={b('item-label')}>{locale.role}</div>
        <div className={b('item-value')}>{role}</div>
      </div>

      <div className={b('item')}>
        <div className={b('item-label')}>{locale.deposits}</div>
        <div className={b('item-value')}>{formatNumber(item.debits)}</div>
      </div>
      <div className={b('item')}>
        <div className={b('item-label')}>{locale.withdrawals}</div>
        <div className={b('item-value')}>{formatNumber(item.credits)}</div>
      </div>
      <div className={b('item')}>
        <div className={b('item-label')}>{locale.total}</div>
        <div className={b('item-value')}>{formatNumber(item.total)}</div>
      </div>
    </div>
  );
};

export default TotalUser;