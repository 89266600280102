export const initialState = {
  isAuth: false,
  name: '',
  balance: 0,
  currency: '',
  role: null,
  id: 0,
  mysteryBalance: 0,
  time_to_next_cashback: '00:00:00',
};
