export class TotalConverter {
  totalConverter = (data) => ({
    info: {
      credits: data.credits_sum,
      debits: data.debits_sum,
      total: data.total,
    },
    users: data.descendants.reduce((acc, t) => {
      if (t.user_id && t.username) {
        acc.push({
          userId: t.user_id,
          username: t.username,
        })
      }

      return acc;
    }, []) ?? []
  });

  fullTotalConverter = (data) => ({
    info: {
      credits: data.credits_sum,
      debits: data.debits_sum,
      total: data.total,
    },
    users: data.descendants.map(t => ({
      userId: t.user_id,
      username: t.username,
      ...this.convertUser(t)
    }))
  });

  convertUser = data => ({
    credits: data.credits_sum,
    debits: data.debits_sum,
    total: data.total,
  });
}
