import React, { useEffect, useMemo, useCallback, useState } from "react";
import block from "bem-cn";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import { actions } from "features/users/redux";

import Input from "components/Input";
import SVG from "components/SVG";
import Button from "components/Button";

import { generatePasswordForUser } from "shared/utils/validation";
import { formatNumber } from "shared/utils/formatNumber";

import keySVG from '../img/key.svg';
import banSVG from '../img/ban.svg';
import unbanSVG from '../img/unban.svg';
import PopUp from "./PopUp";

import "./UserEdit.scss";

const b = block("user-edit");

const UserEdit = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const locale = useSelector((state) => state.locale.locale, shallowEqual);
  const auth = useSelector((state) => state.auth, shallowEqual);

  const [topUpMoney, changeTopUpMoney] = useState("");
  const [withdrawalMoney, setWithdrawalMoney] = useState("");
  const [popUpIsOpen, setPopUpOpened] = useState(false);

  const id = match.params.id;

  const actionProcessing = useSelector(
    (state) => state.users.actionProcessing,
    shallowEqual
  );

  const usersList = useSelector(
    (state) => state.users.usersList.users,
    shallowEqual
  );
  const newPassword = useSelector(
    (state) => state.users.newPassword,
    shallowEqual
  );

  const user = useMemo(() => usersList?.find((t) => +t.id === +id) || {}, [
    id,
    usersList,
  ]);

  useEffect(() => {
    if (!usersList?.length) {
      dispatch(actions.getUsersList());
    }
  }, [dispatch, usersList]);

  const paymentButtonText = useMemo(() => {
    if (withdrawalMoney) return locale.withdrawal;
    if (topUpMoney) return locale.topUp;
    return locale.topUpWithdrawal
  }, [
    locale.topUp,
    locale.topUpWithdrawal,
    locale.withdrawal,
    topUpMoney,
    withdrawalMoney,
  ]);

  const onPaymentClick = useCallback(() => {
    if (!actionProcessing) {
      dispatch(
        actions.changeUserBalance(
          id,
          topUpMoney || withdrawalMoney,
          !!withdrawalMoney,
          () => {
            changeTopUpMoney('');
            setWithdrawalMoney('');
          }
        )
      );
    }
    // history.push('/users-list');
  }, [actionProcessing, dispatch, id, topUpMoney, withdrawalMoney]);

  const onBanClick = useCallback(
    (value) => {
      if (!actionProcessing) dispatch(actions.changeBanState(id, value));
      history.push("/users-list");
    },
    [actionProcessing, dispatch, history, id]
  );

  const onResetClick = useCallback(() => {
    const pas = generatePasswordForUser();
    if (!actionProcessing)
      dispatch(actions.resetPassword(id, setPopUpOpened, pas));
  }, [actionProcessing, dispatch, id]);

  const userRoleName = locale.roles?.[auth.role - 1]?.toLowerCase() ?? '';

  const disabled = topUpMoney === '' && withdrawalMoney === '';

  const handleTopUp = v => {
    changeTopUpMoney(v);
    setWithdrawalMoney('');
  }

  const handleWithdraw = v => {
    setWithdrawalMoney(v);
    changeTopUpMoney('');
  }

  return (
    <div className={b()}>
      {popUpIsOpen && (
        <PopUp
          password={newPassword}
          id={id}
          locale={locale}
          onClose={setPopUpOpened}
        />)}
      <h3 className={b('title')}>{`${locale.about} ${userRoleName}`}</h3>
      <div className={b('block')}>
        <div className={b('row')}>
          <h5 className={b('block-title')}>{locale.userDate}</h5>
          <div className={b("reset-button")} onClick={onResetClick}>
            <SVG className={b('key')} svgProps={{ svg: keySVG }} />
            {locale.resetPassword}
          </div>
        </div>

        <div className={b('fields')}>
          <div className={b('item')}>
            <div className={b('item-label')}>{locale.id}</div>
            <Input value={user.id} disabled />
          </div>
          <div className={b('item')}>
            <div className={b('item-label')}>{locale.userName}</div>
            <Input value={user.nickname} disabled />
          </div>
        </div>

        <div className={b('line')} />

        <div className={b('row')}>
          <Button onClick={() => onBanClick(true)}>
            <SVG className={b('icon')} svgProps={{ svg: banSVG }} />
            {locale.ban}
          </Button>
          <Button color="grey" onClick={() => onBanClick(false)}>
            <SVG className={b('icon')} svgProps={{ svg: unbanSVG }} />
            {locale.unban}
          </Button>
        </div>
      </div>

      <div className={b('block')}>
        <h5 className={b('block-title')}>{locale.balance}</h5>

        <div className={b('fields')}>
          <div className={b('item')}>
            <div className={b('item-label')}>{locale.balance}</div>
            <Input
              value={formatNumber(user.balance)}
              schema="bordered"
              measure={auth.currency}
              disabled
            />
          </div>
          <div className={b('item')}>
            <div className={b('item-label')}>{locale.topUp}</div>
            <Input
              type="number"
              value={topUpMoney}
              measure={auth.currency}
              onChange={e => handleTopUp(e.currentTarget.value)}
              placeholder="0.00"
            />
          </div>
          <div className={b('item')}>
            <div className={b('item-label')}>{locale.withdraw}</div>
            <Input
              type="number"
              value={withdrawalMoney}
              measure={auth.currency}
              onChange={e => handleWithdraw(e.currentTarget.value)}
              placeholder="0.00"
            />
          </div>
        </div>

        <Button onClick={onPaymentClick} text={paymentButtonText} disabled={disabled} />
      </div>
    </div>
  );
};

export default UserEdit;
