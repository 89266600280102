import React, { useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import Input from 'components/Input';
import Button from 'components/Button';
import SVG from 'components/SVG';
import SetLanguage from 'components/SetLanguage';

import logoSVG from 'shared/img/logo.svg';
import { actions } from '../redux';

import './Auth.scss';

const b = block('auth');

const Auth = () => {
  const [userName, changeUserName] = useState('');
  const [password, passwordUserName] = useState('');
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();

  const handleSignIn = e => {
    e.preventDefault();
    dispatch(actions.login(userName, password));
  };

  const disabled = !userName || !password;

  return (
    <section className={b()}>
      <form className={b('content')} onSubmit={handleSignIn}>
        <SVG className={b('logo')} svgProps={{ svg: logoSVG }} />
        <div className={b('username')}>
          <Input
            value={userName}
            onChange={e => changeUserName(e.currentTarget.value)}
            label={<div className={b('label')}>{locale.userName}</div>}
            placeholder={locale.userName}
            schema="bordered"
          />
        </div>
        <div className={b('password')}>
          <Input
            value={password}
            onChange={e => passwordUserName(e.currentTarget.value)}
            type="password"
            label={<div className={b('label')}>{locale.password}</div>}
            placeholder={locale.password}
            schema="bordered"
            isPassword
          />
        </div>
        <div className={b('button')}>
          <Button type="submit" text={locale.login} disabled={disabled} />
        </div>
      </form>
      <div className={b('footer')}>
        <div className={b('other')}>{locale.other}</div>
        <div className={b('menu')}>
          <SetLanguage />
        </div>
      </div>
    </section>
  );
};


export default Auth;
