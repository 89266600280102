import React, { useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import Input from 'components/Input';
import Button from 'components/Button';
import SVG from 'components/SVG';
import Modal from 'components/Modal';

import { actions as userActions } from 'features/users';

import './ChangePassword.scss';

const b = block('change-password');

const ChangePassword = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const locale = useSelector(state => state.locale.locale);
  const id = useSelector(state => state.auth.id);
  const dispatch = useDispatch();

  const handleSignIn = e => {
    e.preventDefault();
    dispatch(userActions.resetPassword(id, () => setIsOpen(false), newPassword));
  };

  const disabled = !newPassword || newPassword !== confirmPassword;

  return (
    <section className={b()}>
      {isOpen && <Modal onClose={() => setIsOpen(false)}>
        <form className={b('content')} onSubmit={handleSignIn}>
          <h5 className={b('title')}>{locale.changePas}</h5>

          <div className={b('items')}>
            <div className={b('item')}>
              <div className={b('item-label')}>{locale.newPassword}</div>
              <div className={b('item-field')}>
                <Input
                  value={newPassword}
                  onChange={e => setNewPassword(e.currentTarget.value)}
                  placeholder={locale.newPassword}
                  isPassword
                />
              </div>
            </div>

            <div className={b('item')}>
              <div className={b('item-label')}>{locale.confirmNewPassword}</div>
              <div className={b('item-field')}>
                <Input
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.currentTarget.value)}
                  type="password"
                  placeholder={locale.confirmNewPassword}
                  isPassword
                />
              </div>
            </div>
          </div>

          <div className={b('note')}>{locale.errorPassword}</div>

          <div className={b('buttons')}>
            <div className={b('button')}>
              <Button type="submit" text={locale.applyChanges} disabled={disabled} />
            </div>
            <div className={b('button')}>
              <Button text={locale.cancel} color="transparent" onClick={() => setIsOpen(false)} />
            </div>
          </div>
        </form>
      </Modal>}
      <div className={b('children')} onClick={() => setIsOpen(!isOpen)}>{children}</div>
    </section>
  );
};


export default ChangePassword;
