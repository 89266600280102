/* eslint-disable no-continue */
import React, { useMemo, useCallback } from 'react';
import block from 'bem-cn';

import SVG from 'components/SVG';

import arrowSVG from './img/arrow.svg';

import './Paginator.scss';

const b = block('paginator-mobile');

const Paginator = ({ count, currentPage, onPageClick }) => {
  const canClickArrowLeft = useMemo(() => currentPage > 0, [currentPage]);
  const canClickArrowRight = useMemo(() => currentPage < count - 1, [currentPage, count]);

  const handleLeftArrowClick = useCallback(() => {
    if (canClickArrowLeft) onPageClick(currentPage - 1);
  }, [canClickArrowLeft, currentPage, onPageClick]);

  const handleRightArrowClick = useCallback(() => {
    if (canClickArrowRight) onPageClick(currentPage + 1);
  }, [canClickArrowRight, currentPage, onPageClick]);


  return (
    <div className={b()}>
      <div className={b('arrow-left-container')}>
        <SVG className={b('arrow-left')} svgProps={{ svg: arrowSVG }} onClick={handleLeftArrowClick} />
      </div>
      <div className={b('item')}>{currentPage + 1}</div>
      <div className={b('arrow-right-container')}>
        <SVG className={b('arrow-right')} svgProps={{ svg: arrowSVG }} onClick={handleRightArrowClick} />
      </div>
    </div>
  );
};

export default Paginator;
