import usersSVG from '../img/users.svg';
import plusSVG from '../img/plus.svg';
import myTransfersSVG from '../img/my-transfers.svg';
import transactionsSVG from '../img/transactions.svg';

export const sideMenuItems = [
  [
    {
      icon: usersSVG,
      link: '/users-list',
      text: 'users',
      additional: {
        icon: plusSVG,
        // link: '/user-create',
      },
    },
  ],
  [
    {
      icon: myTransfersSVG,
      link: '/total',
      text: 'myTransfers',
    },
    {
      icon: transactionsSVG,
      link: '/transaction-history',
      text: 'transactionsHistory',
    },
  ]
]
