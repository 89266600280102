import React, { useEffect, useState, useMemo, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { actions } from 'features/users/redux';

import Input from 'components/Input';
import SVG from 'components/SVG';
import Button from 'components/Button';
import Paginator from 'components/Paginator';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

import { formatNumber } from 'shared/utils/formatNumber';

import CreateUser from '../CreateUser';

import UserItem from './UserItem';
import searchSvg from '../img/search.svg';

import './UsersList.scss';

const b = block('users-list');

const itemsOnPage = 10;

const UsersList = () => {
  const [filterValue, onChangeFilterValue] = useState('');
  const [page, setPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUsersList());
  }, [dispatch]);

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const usersList = useSelector(state => state.users.usersList, shallowEqual);
  const actionProcessing = useSelector(state => state.users.actionProcessing, shallowEqual);
  const user = useSelector(state => state.auth);

  const [balanceWhole, balanceFraction] = formatNumber(usersList.totalBalance).split('.');

  const filteredUsers = useMemo(() => usersList.users?.filter(t =>
    ~t.nickname?.toUpperCase().indexOf(filterValue.toUpperCase()) || false)
    , [filterValue, usersList])

  const list = useMemo(() => filteredUsers
    .slice(page * itemsOnPage, (page + 1) * itemsOnPage)
    .map(item => (
        <UserItem item={item} key={item.id} />
      )) || [], [filteredUsers, page]);

  const pages = Math.ceil(filteredUsers.length / itemsOnPage);

  useEffect(() => {
    if (page >= pages) setPage(0);
  }, [pages, page]);

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      {isModalOpen &&
        <Modal onClose={() => setIsModalOpen(false)}>
          <CreateUser onClose={() => setIsModalOpen(false)} />
        </Modal>}
      <div className={b('title')}>{locale.users}</div>
      <div className={b('block')}>
        {locale.totalUsers}
        <div className={b('block-value')}>
          {usersList.quantity}
        </div>
      </div>
      <div className={b('block')}>
        {locale.accountBalance}
        <div className={b('block-value')}>
          {balanceWhole}
          <span className={b('currency')}>.{balanceFraction}</span>
          <span className={b('currency')}>{user.currency}</span>
        </div>
      </div>

      <div className={b('block')}>
        <div className={b('search')}>
          <Input
            icon={<SVG svgProps={{ svg: searchSvg }} className={b('icon')} />}
            value={filterValue}
            callBack={changeFilterValue}
            placeholder={locale.search}
          />
          <Button text={locale.search} />
        </div>
      </div>

      <div className={b('button')}>
        <Button text={locale.createUser[user.role]} onClick={() => setIsModalOpen(true)} />
      </div>

      <div className={b('block', { type: 'big' })}>
        <div className={b('results')}>{locale.results}:</div>
        <div className={b('list')}>
          {list}
        </div>
        {!!filteredUsers.length && <div className={b('paginator')}>
          <Paginator onPageClick={setPage} currentPage={page} count={pages} />
        </div>}
      </div>
    </div>
  );
};

export default UsersList;