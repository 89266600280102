import React from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual } from 'react-redux';

import SVG from 'components/SVG';
import SetLanguage from 'components/SetLanguage';
import LogOut from 'components/LogOut';
import ChangePassword from 'features/users/view/ChangePassword';

import userSVG from 'shared/img/user.svg';
import langSVG from 'shared/img/lang.svg';
import lockSVG from 'shared/img/lock.svg';
import logoutSVG from 'shared/img/logout.svg';
import { formatNumber } from 'shared/utils/formatNumber';

import './UserInfo.scss';

const b = block('user-info');

const UserInfo = () => {
  const locale = useSelector(state => state.locale.locale);
  const auth = useSelector(state => state.auth, shallowEqual);

  const roleName = locale?.roles?.[auth.role] ?? '';

  const [balanceWhole, balanceFraction] = formatNumber(auth.balance).split('.');

  return (
    <div className={b()}>
      <div className={b('header')}>
        <SVG className={b('user')} svgProps={{ svg: userSVG }} />
        <div className={b('data')}>
          <div className={b('data-name')}>{auth.name}</div>
          <div className={b('data-id')}>{`${roleName} ID: ${auth.id}`}</div>
        </div>
      </div>
      <div className={b('balance')}>
        <div className={b('balance-title')}>{locale.myBalance}</div>
        <div className={b('balance-value')}>
          {balanceWhole}
          <span className={b('balance-currency')}>.{balanceFraction}</span>
          <span className={b('balance-currency')}>{auth.currency}</span>
        </div>
      </div>
      <SetLanguage>
        <div className={b('item')}>
          <SVG className={b('item-icon')} svgProps={{ svg: langSVG }} />
          {locale.changeLang}
        </div>
      </SetLanguage>
      <ChangePassword>
        <div className={b('item')}>
          <SVG className={b('item-icon')} svgProps={{ svg: lockSVG }} />
          {locale.changePas}
        </div>
      </ChangePassword>
      <LogOut>
        <div className={b('item')}>
          <SVG className={b('item-icon')} svgProps={{ svg: logoutSVG }} />
          {locale.logout}
        </div>
      </LogOut>
    </div>
  );
};

export default UserInfo;
