import React, { useEffect, useRef } from 'react';
import block from 'bem-cn';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { actions as authAction } from './features/Auth';
import Notify from 'features/notify/view';
import CreateUser from './features/users/view/CreateUser';
import UsersList from './features/users/view/UsersList';
import UserEdit from './features/users/view/UserEdit';
import ChangePas from './features/changePas';
import { Tree } from './features/tree';
import { Total } from './features/Total';

import TransactionHistory from 'features/transaction/view/TransactionHistory';
import { Locale } from 'features/locale';
import { Auth } from 'features/Auth';
import { actions as appActions } from 'features/app';

import Footer from 'components/Footer';
import Header from 'components/Header';

import Subheader from 'components/Subheader';
import Menu from 'components/Menu';
import SlideOut from 'components/SlideOut';

import { SlideOutModel } from 'shared/models/SlideOutModel';

import './App.scss';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const b = block('app');

const App = () => {
  const locale = useSelector(state => state.locale.locale);
  const isAuth = useSelector(state => state.auth.isAuth);
  const dispatch = useDispatch();
  const appRef = useRef(null);

  useEffect(() => {
    dispatch(authAction.checkAuth());
  }, [dispatch]);

  useEffect(() => {
    const touchListener = new SlideOutModel(value =>
      dispatch(appActions.changeSideMenuOpened(value)), appRef.current);

    touchListener.init();
  }, [dispatch]);

  return (
    <div className={b()} ref={appRef}>
      <Notify />
      {isAuth &&
        <SlideOut>
          <Header />
          <div className={b('content')}>
            <Switch>
              <Route exact key="/user-create" path="/user-create" component={CreateUser} />
              {/* <Route exact key="/locale" path="/locale" component={Locale} /> */}
              <Route exact key="/users-list" path="/users-list" component={UsersList} />
              {/* <Route exact key="/" path="/">
                <Menu />
                <Footer locale={locale} isAuth={isAuth} />
              </Route> */}
              <Route exact key="/total" path="/total" component={Total} />
              <Route exact key="/user-edit/:id" path="/user-edit/:id" component={UserEdit} />
              <Route exact key="/transaction-history" path="/transaction-history" component={TransactionHistory} />
              {/* <Route exact key="/change-pas" path="/change-pas" component={ChangePas} /> */}
              {/* <Route exact key="/tree" path="/tree" component={Tree} /> */}
              <Redirect to="/users-list" />
            </Switch>
          </div>
      </SlideOut>}
      {!isAuth && <Auth />}
    </div>
  );
};

export default App;
