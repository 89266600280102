import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SVG from 'components/SVG';

import { actions as appActions } from 'features/app';

import hamburgerSVG from 'shared/img/hamburger.svg';
import logoSVG from 'shared/img/logo.svg';

import UserInfo from './UserInfo';
import SideMenu from './SideMenu';
import './SlideOut.scss';

const b = block('slide-out');

const SlideOut = ({ children }) => {
  const dispatch = useDispatch();

  const { sideMenuOpened } = useSelector(state => state.app, shallowEqual);

  const handleHamburgerClick = () => {
    dispatch(appActions.changeSideMenuOpened(!sideMenuOpened));
  }

  return (
    <div id="slide-out" className={b({ open: sideMenuOpened })}>
      <div className={b('menu')}>
        <div className={b('header')}>
          <SVG
            className={b('hamburger')}
            svgProps={{ svg: hamburgerSVG }}
            onClick={handleHamburgerClick}
          />
          <Link to="/" onClick={handleHamburgerClick} className={b('logo-wrapper')}>
            <SVG
              className={b('logo')}
              svgProps={{ svg: logoSVG }}
            />
          </Link>
        </div>
        <div className={b('items')}>
          <UserInfo />
          <SideMenu />
        </div>
      </div>
      <div className={b('content')}>{children}</div>
    </div>
  );
};

SlideOut.propTypes = {
  children: PropTypes.array.isRequired,
};

export default SlideOut;
