import React from 'react';
import block from 'bem-cn';

import Button from 'components/Button';
import SVG from 'components/SVG';
import Copy from 'components/Copy';

import copySVG from 'shared/img/copy.svg';

import './PopUp.scss';

const b = block('password-pop-up');

const PopUp = ({ password, id, locale, onClose }) => {
  return (
    <div className={b()}>
      <div className={b('body')}>
        <div className={b('header')}>
          {`${locale.theNewPassword} ${locale.for} ${locale.id} ${id}`}
        </div>
        <div className={b('password')}>
          {password}
          <Copy value={password}>
            <SVG className={b('copy')} svgProps={{ svg: copySVG }} />
          </Copy>
        </div>
        <div className={b('button')}>
          <Button text={locale.ok} onClick={() => onClose(false)} />
        </div>
      </div>
    </div>
  );
};

export default PopUp;