import React from 'react';
import block from 'bem-cn';

import { DatePicker } from '@nirall/react-date-picker-chrome';

import './InputDate.scss';

const b = block('input-date');

const datePickerStyle = {
  '--text': '#fff',
  '--selected-text': '#fff',
  '--selected-bg': '#E9A722',
  '--button': '#E9A722',
  '--main-bg': '#132C46',
  '--icon': '#E9A722',
  '--border': 'transparent',
  '--year': 'rgba(255, 255, 255, 0.5)',
  // '--radius': string,
}

const InputDate = ({ value, onChange, dateFormat = 'DD.MM.YYYY', withTime = false  }) => {
  return (
    <div className={b()}>
      <DatePicker
        value={value}
        onChange={onChange}
        dateFormat={dateFormat}
        startYear={2015}
        yearsCount={30}
        withTime={withTime}
        style={datePickerStyle}
      />
    </div>
  );
};

export default InputDate;