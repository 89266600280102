import { initialState } from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT:
      return { ...initialState };
    case actionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        name: action.payload.login,
        currency: action.payload.currency,
        role: action.payload.role,
        id: action.payload.id,
      };
    case actionTypes.GET_BALANCE: {
      return {
        ...state,
        ...action.payload,
        mysteryBalance:
          action.payload.mysteryBalance < 0 || !action.payload.mysteryBalance ? 0 : action.payload.mysteryBalance,
      };
    }
    default:
      return state;
  }
};
