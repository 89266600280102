import AuthApi from "./AuthApi";
import UserApi from "./UserApi";
import TotalApi from "./TotalApi";
import TransactionApi from "./TransactionApi";
import TreeApi from "./TreeApi";
class Api {
  constructor(baseUrl = "") {
    this.auth = new AuthApi(baseUrl);
    this.user = new UserApi(baseUrl);
    this.total = new TotalApi(baseUrl);
    this.transaction = new TransactionApi(baseUrl);
    this.tree = new TreeApi(baseUrl);
  }
}

export default Api;
