import React, { useState } from 'react';
import block from 'bem-cn';

import SVG from 'components/SVG';

import passwordSVG from './img/password.svg';
import passwordOffSVG from './img/passwordOff.svg';
import './Input.scss';

const b = block('input');

const Input = ({ value, name, callBack, placeholder = '', size = 'default', type = 'text',
  isRequired, label, schema, isPassword, icon, measure, ...props }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordClick = () => setPasswordVisible(!passwordVisible && isPassword);

  return (
    <div className={b('wrapper', { schema, withLabel: !!label })}>
      {icon}
      <div className={b('data')}>
        {label}
        <input
          type={(passwordVisible) ? 'text' : type}
          name={name}
          value={value}
          placeholder={placeholder || ''}
          onChange={callBack}
          className={b({ activePassword: isPassword && !passwordVisible && !!value })}
          required={isRequired}
          {...props}
          />
      </div>
      {measure && <div className={b('measure')}>{measure}</div>}
      {isPassword && (
        <SVG
          svgProps={{ svg: passwordVisible ? passwordOffSVG : passwordSVG}}
          className={b('password', { active: !passwordVisible })}
          onClick={handlePasswordClick}
        />)}
    </div>
  );
};

export default Input;