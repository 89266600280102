import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SVGInline from 'react-svg-inline';

import Modal from 'components/Modal';

import home from './img/home.svg';
import cross from './img/cross.svg';

import './Subheader.scss';

const b = block('subheader');

const Subheader = ({ getBalance, user }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { balance, currency, mysteryBalance, time_to_next_cashback, role } = useSelector(
    state => state.auth,
    shallowEqual,
  );

  const [isModalOpened, setIsModalOpened] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    const value = setInterval(() => dispatch(getBalance()), 3000);
    return () => {
      clearInterval(value);
    };
  }, []);

  return (
    <>
      <article className={b()}>
        {`${user.name}: ${user.balance} ${user.currency}`}
        {role === 1 && (
          <>
            <div className={b('mystery-balance')}>
              {locale.mysteryBalance}: {mysteryBalance} {currency}
            </div>
            <div className={b('mystery-balance-info')} onClick={() => setIsModalOpened(true)}>
              ?
            </div>
          </>
        )}
        <Link to="/" className={b('home')}>
          <SVGInline svg={home} className={'image'} />
        </Link>
      </article>
      {isModalOpened ? (
        <Modal onClose={() => setIsModalOpened(false)}>
          <div className={b('info-content')}>
            <div className={b('info-cross')}>
              <SVGInline
                className={b('info-cross-icon').toString()}
                svg={cross}
                onClick={() => setIsModalOpened(false)}
              />
            </div>
            <div className={b('info-img')} />
            <div className={b('info-text1')}>{locale.mysteryBalance}</div>
            <div className={b('info-text2')} dangerouslySetInnerHTML={{ __html: locale.mysteryBalanceInfo }} />
            <div className={b('info-timer')}>{time_to_next_cashback}</div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default Subheader;
