import React, { useState, useCallback, useEffect, useMemo } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import dayjs from 'dayjs';

import Spinner from 'components/Spinner';
import SVG from 'components/SVG';
import Button from 'components/Button';
import Paginator from 'components/Paginator';
import InputDate from 'components/InputDate';

import { formatNumber } from 'shared/utils/formatNumber';

import TotalUser from './TotalUser';
import { useTotalInfo } from './data/useTotalInfo';
import { actions } from '../redux';
import './Total.scss';

const b = block('total');

const itemsOnPage = 10;

const Total = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const totalInfo = useSelector(state => state.total.totalInfo);
  const auth = useSelector(state => state.auth, shallowEqual);
  const userList = useSelector(state => state.total.users, shallowEqual);
  const actionProcessing = useSelector(state => state.total.actionProcessing);
  const totalData = useTotalInfo();

  const [fromDate, setFromDate] = useState(dayjs().add(-3, 'day').toDate());
  const [toDate, setToDate] = useState(new Date());
  const [page, setPage] = useState(0);

  const handleFormSubmit = e => {
    e.preventDefault();
    dispatch(actions.getTotal(fromDate, toDate));
  }

  const onUserClick = useCallback(id => {
    if (!actionProcessing) {
      dispatch(actions.getUserData(id, fromDate, toDate));
    }
  }, [actionProcessing, dispatch, fromDate, toDate]);

  const cashierData = {
    userId: auth.id,
    username: auth.name,
    role: auth.role + 1,
    debits: totalInfo.debits,
    credits: totalInfo.credits,
    total: totalInfo.total,
  }

  const cashier = useMemo(() => {
    return auth.role === 1 && (
      <TotalUser item={cashierData} />
    );
  }, [auth.role, cashierData]);


  const users = useMemo(() => userList
    .slice(page * itemsOnPage, (page + 1) * itemsOnPage)
    .map((t, index) => (
      <TotalUser item={t} onClick={() => onUserClick(t.userId)} key={`${t.userId}_${index}`} />
    )), [onUserClick, userList, page])

  const totalItems = totalData.map(item => {
    return (
      <div className={b('total')} key={item.text}>
        <SVG className={b('total-icon')} svgProps={{ svg: item.icon }} />
        <div className={b('total-data')}>
          <div className={b('total-label')}>{locale[item.text] ?? item.text}</div>
          <div className={b('total-value' , { type: item.text })}>{formatNumber(item.value)}</div>
        </div>
        <div className={b('total-currency')}>{auth.currency}</div>
      </div>
    )
  });

  const pages = Math.ceil(userList.length / itemsOnPage);

  useEffect(() => {
    dispatch(actions.getTotal(fromDate, toDate));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (page >= pages) setPage(0);
  }, [pages, page]);

  return <section className={b()}>
    <Spinner isLoading={actionProcessing} />
    <div className={b('title')}>{locale.myTransfers}</div>

    <div className={b('block')}>{totalItems}</div>

    <form className={b('block')} onSubmit={handleFormSubmit}>
      <div className={b('item')}>
        <div className={b('item-label')}>{locale.from}</div>
        <div className={b("item-field")}>
          <InputDate
            value={fromDate}
            onChange={setFromDate}
          />
        </div>
      </div>

      <div className={b('item')}>
        <div className={b('item-label')}>{locale.to}</div>
        <div className={b("item-field")}>
          <InputDate
            value={toDate}
            onChange={setToDate}
          />
        </div>
      </div>

      <Button type="submit" text={locale.show} />
    </form>
    <div className={b('block', { type: 'big' })}>
      {cashier}
      {users}
      {!!userList.length && <div className={b('paginator')}>
        <Paginator onPageClick={setPage} currentPage={page} count={pages} />
      </div>}
    </div>
  </section>
}

export default Total;
