import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import block from "bem-cn";
import { useSelector, useDispatch } from "react-redux";

import Input from "components/Input";
import Button from "components/Button";

import { actions } from "features/users/redux";
import { actions as notifyActions } from "features/notify";

import { validateUserPassword } from "shared/utils/validation";
import "./CreateUser.scss";

const b = block("create-user");

const CreateUser = ({ onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector((state) => state.locale.locale);
  const actionProcessing = useSelector((state) => state.users.actionProcessing);
  const auth = useSelector((state) => state.auth);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const handleFormSubmit = useCallback((e) => {
    e.preventDefault();
    const validation = validateUserPassword(password);
    if (validation.isValid && !actionProcessing) {
      dispatch(actions.createUser(userName, password, () => history.push("/")));
    }
    if (!validation.isValid) {
      dispatch(
        notifyActions.addNotify(
          validation.errors.reduce((acc, t) => acc + `${locale[t]}. `, ""),
          "error"
        )
      );
    }
  }, [actionProcessing, dispatch, password, userName, history, locale]);

  const disabled = !password || password !== repeatPassword || !userName;

  return (
    <form className={b()} onSubmit={handleFormSubmit}>
      <div className={b("header")}>
        {`${locale.create} ${locale.createUser[auth.role]?.toLowerCase() ?? ''}`}
      </div>

      <div className={b("items")}>
        <div className={b("item")}>
          <div className={b("item-label")}>{locale.userName}</div>
          <Input
            placeholder={locale.userName}
            value={userName}
            callBack={(e) => setUserName(e.currentTarget.value)}
          />
        </div>
        <div className={b("item")}>
          <div className={b("item-label")}>{locale.password}</div>
          <Input
            placeholder={locale.password}
            value={password}
            callBack={(e) => setPassword(e.currentTarget.value)}
            isPassword
          />
        </div>
        <div className={b("item")}>
          <div className={b("item-label")}>{locale.repeatPassword}</div>
          <Input
            placeholder={locale.repeatPassword}
            value={repeatPassword}
            callBack={(e) => setRepeatPassword(e.currentTarget.value)}
            isPassword
          />
        </div>
      </div>

      <div className={b('line')} />

      <div className={b("item")}>
        <div className={b("item-label")}>{locale.role}</div>
        <Input
          value={locale.roles[auth.role - 1]}
          disabled
        />
      </div>

      <div className={b('line')} />

      <div className={b("items")}>
        <Button
          type="submit"
          text={`${locale.create} ${locale.roles[auth.role - 1]?.toLowerCase() ?? ''}`}
          disabled={disabled}
        />
        <Button
          type="button"
          text={locale.cancel}
          onClick={() => onClose()}
          color="transparent"
        />
      </div>
    </form>
  );
};

export default CreateUser;
