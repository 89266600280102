import React from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual } from 'react-redux';
import dayjs from 'dayjs';

import { formatNumber } from 'shared/utils/formatNumber';

import './TransactionItem.scss';

const b = block('transaction-item');

const TransactionItem = ({ item }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const user = useSelector(state => state.auth);

  const role = locale.roles[user.role - 1];
  const operationType = item.amount > 0 ? locale.deposit : locale.withdrawal;
  const amount = `${item.amount}`.replace('-', '');

  return (
    <div className={b()}>
      <div className={b('item')}>
        <div className={b('item-label')}>{locale.userName}</div>
        <div className={b('item-value')}>{item.email}</div>
      </div>
      <div className={b('item')}>
        <div className={b('item-label')}>{locale.role}</div>
        <div className={b('item-value')}>{role}</div>
      </div>
      <div className={b('item')}>
        <div className={b('item-label')}>{locale.date}</div>
        <div className={b('item-value')}>{dayjs(item.date).format('DD.MM.YY, HH:mm')}</div>
      </div>

      <div className={b('item')}>
        <div className={b('item-label')}>{locale.balance}</div>
        <div className={b('item-value')}>{formatNumber(item.balance)}</div>
      </div>
      <div className={b('item')}>
        <div className={b('item-label')}>{locale.operationType}</div>
        <div className={b('item-value')}>{operationType}</div>
      </div>
      <div className={b('item')}>
        <div className={b('item-label')}>{locale.amount}</div>
        <div className={b('item-value')}>{formatNumber(amount)}</div>
      </div>
    </div>
  );
};

export default TransactionItem;