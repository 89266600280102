import React, { useState } from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';

import './Button.scss';

const b = block('button');

const Button = ({
  disabled = false,
  color = '',
  type = 'button',
  link,
  onClick,
  children,
  text,
  ...props
}) => {

  const handleClick = e => {
    if (disabled) {
      e.preventDefault();
    } else if (onClick) {
      onClick(e);
    }
  }

  if(link) return (
    <Link className={b({ disabled, color })} to={link} onClick={handleClick}>
      {children ?? text}
    </Link>
  )

  return (
    <button
      onClick={handleClick}
      type={type}
      className={b({ disabled, color })}
      {...props }
    >
    {children ?? text}
    </button>
  )
};

export default Button;