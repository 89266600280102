import React, { useState, useCallback, useMemo, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { actions } from '../../redux';

import InputDate from 'components/InputDate';
import Button from 'components/Button';
import Paginator from 'components/Paginator';
import CheckBox from 'components/CheckBox';
import Spinner from 'components/Spinner';

import TransactionItem from './TransactionItem';

import './TransactionHistory.scss';

const b = block('transaction-history');

const itemsOnPage = 10;

const TransactionHistory = () => {
  const dispatch = useDispatch();
  // const [filterValue, onChangeFilterValue] = useState('');
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const transactions = useSelector(state => state.transaction.transactions, shallowEqual);
  const actionProcessing = useSelector(state => state.transaction.actionProcessing, shallowEqual);

  const [startDate, setStartDate] = useState(dayjs().add(-3, 'day').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(0);
  const [types, setTypes] = useState([0, 1]);

  const handleFormSubmit = useCallback((e) => {
    e.preventDefault();
    dispatch(actions.getTransactions({ startDate, endDate }))
  }, [dispatch, endDate, startDate]);

  const handleStatusChange = e => {
    const value = Number(e.target.name);

    setTypes(ps => ps.includes(value)
      ? ps.filter(type => type !== value)
      : [ ...ps, value]);
  };

  const filteredTransactions = useMemo(() => transactions
    ?.filter(t => types.includes(t.type))
    // .filter(t => ~t.email?.toUpperCase().indexOf(filterValue.toUpperCase()) || false)
  , [transactions, types]);

  const list = useMemo(() => filteredTransactions
    .slice(page * itemsOnPage, (page + 1) * itemsOnPage)
    .map((item, index) => (
      <TransactionItem key={`${item.email}_${index}`} item={item} />
    )) || [], [filteredTransactions, page]);


  const pages = Math.ceil(filteredTransactions.length / itemsOnPage);

  useEffect(() => {
    dispatch(actions.getTransactions({ startDate, endDate }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      <div className={b('title')}>{locale.transactionsHistory}</div>

      <form className={b('block')} onSubmit={handleFormSubmit}>
        <div className={b('checkboxes')}>
          <div className={b('checkboxes-title')}>{locale.operation}:</div>
          <div />
          <label className={b('checkbox')}>
            <div className={b('checkbox-input')}>
              <CheckBox name="1" checked={types.includes(1)} onChange={handleStatusChange} />
            </div>
            <span className={b('checkbox-text')}>{locale.operationTypes[1]}</span>
          </label>

          <label className={b('checkbox')}>
            <div className={b('checkbox-input')}>
              <CheckBox name="0" checked={types.includes(0)} onChange={handleStatusChange} />
            </div>
            <span className={b('checkbox-text')}>{locale.operationTypes[0]}</span>
          </label>
        </div>

        <div className={b('item')}>
          <div className={b('item-label')}>{locale.from}</div>
          <div className={b("item-field")}>
            <InputDate
              value={startDate}
              onChange={date => setStartDate(date)}
            />
          </div>
        </div>

        <div className={b('item')}>
          <div className={b('item-label')}>{locale.to}</div>
          <div className={b("item-field")}>
            <InputDate
              value={endDate}
              onChange={date => setEndDate(date)}
            />
          </div>
        </div>

        <Button type="submit" text={locale.show} />
      </form>

      <div className={b('block', { type: 'big' })}>
        {list}
        {!!list.length && <div className={b('paginator')}>
          <Paginator onPageClick={setPage} currentPage={page} count={pages} />
        </div>}
      </div>
    </div>
  );
};

export default TransactionHistory;