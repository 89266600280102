import React from 'react';
import block from 'bem-cn';
import { withRouter } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import SVG from 'components/SVG';
import LogOut from 'components/LogOut';

import { actions as appActions } from 'features/app';

import hamburgerSVG from 'shared/img/hamburger.svg';
import userSVG from 'shared/img/user.svg';

import logoutSVG from './img/logout.svg';
import './Header.scss';

const b = block('header');

const Header = () => {
  const locale = useSelector(state => state.locale.locale);
  const role = useSelector(state => state.auth.role, shallowEqual);
  const name = useSelector(state => state.auth.name, shallowEqual);
  const id = useSelector(state => state.auth.id, shallowEqual);
  const sideMenuOpened = useSelector(state => state.app.sideMenuOpened, shallowEqual);
  const dispatch = useDispatch();

  const handleHamburgerClick = () => {
    dispatch(appActions.changeSideMenuOpened(!sideMenuOpened));
  }

  const roleName = locale.roles[role] ?? '';

  return (
    <div className={b()}>
      <SVG
       className={b('hamburger')}
       svgProps={{ svg: hamburgerSVG }}
       onClick={handleHamburgerClick}
      />
      <SVG className={b('user')} svgProps={{ svg: userSVG }} />
      <div className={b('data')}>
        <div className={b('data-name')}>{name}</div>
        <div className={b('data-id')}>{`${roleName} ID: ${id}`}</div>
      </div>
      <div className={b('logout-wrapper')}>
        <LogOut>
          <SVG className={b('logout')} svgProps={{ svg: logoutSVG }} />
        </LogOut>
      </div>
    </div>
  );
};

export default withRouter(Header);
