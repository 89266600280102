import { useSelector } from 'react-redux'

import depositsSVG from '../img/deposits.svg';
import withdrawalsSVG from '../img/withdrawals.svg';
import totalSVG from '../img/total.svg';

export const useTotalInfo = () => {
  const totalInfo = useSelector(state => state.total.totalInfo);

  return (
    [
      {
        icon: depositsSVG,
        text: 'totalDeposits',
        value: totalInfo.debits,
      },
      {
        icon: withdrawalsSVG,
        text: 'totalWithdrawals',
        value: totalInfo.credits,
      },
      {
        icon: totalSVG,
        text: 'total',
        value: totalInfo.total,
      },
    ]
  )
};