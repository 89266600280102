import { initialState } from './initial';
import { actionTypes } from './actions';

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_SIDE_MENU_OPENED:
      return {
        ...state,
        sideMenuOpened: action.payload,
      };

    case actionTypes.CHANGE_DOWN_MENU_OPENED:
      return {
        ...state,
        downMenuOpened: action.payload,
      };

    case actionTypes.CHANGE_THEME:
      return {
        ...state,
        isDark: action.payload,
      };

    default:
      return state;
  }
}
