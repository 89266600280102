import React from 'react';
import SVGInline from 'react-svg-inline';
import block from 'bem-cn';
import PT from 'prop-types';

import './SVG.scss';

const b = block('SVG-component');

const SVG = ({ svgProps, ...restProps }) => (
    <span className={b()} {...restProps}>
      <SVGInline {...svgProps} className={b('content').toString()} />
    </span>
  );

SVG.propTypes = {
  svgProps: PT.shape({
    svg: PT.string
  }),
}

export default SVG;
